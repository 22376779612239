<template>
  <div class="main">
    <div class="d-flex justify-content-center" v-if="loadingSpinner">
      <b-spinner
        variant="success"
        label="Spinning"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <div v-if="!loadingSpinner">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group label="Opportunity Name" label-for="mc-opp-name">
              <b-form-input id="mc-opp-name" type="text" value="Opportunity A" readonly />
            </b-form-group>
          </b-col>
         
          <b-col md="6">
            <b-form-group label="Customer / Lead" label-for="cust-lead">
              <b-form-input id="cust-lead" type="text" value="Person 1" readonly />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Status"
              label-for="status"
            >
            <b-form-select
                  id="status-select"
                  value="New"
                  :options="statusOptions"
                  required
                  :disabled="isSelectDisabled"
                ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Sales Stage" label-for="sales-stage">
              <b-form-input id="sales-stage" type="text" value="Qualification" readonly />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label-for="salesperson"
              label="Assigned to"
            >
              <div class="form-label-group">
                <b-form-input id="salesperson" type="text" value="Salesperson A" readonly />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label-for="amt"
              label="Amount"
            >
              <div class="form-label-group">
                <b-form-input id="amt" type="text" value="10000"  :readonly="isAmountReadOnly" />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="open-date" label="Open Date">
              <b-form-input id="open-date" value="2023-12-01" readonly />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Close Date" label-for="close-date">
              <b-form-input id="close-date" value="2023-12-01" readonly />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Days in tunnel" label-for="tunnel-days">
              <b-form-input id="tunnel-days" type="text" value="10" readonly />
            </b-form-group>
          </b-col>
          <!-- <b-col cols="12" class="justify-content-end d-flex">
            <b-button variant="primary">Save</b-button>
          </b-col> -->
        </b-row>
      </b-form>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BContainer,
  BCard,
  BSpinner,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormSelect,
  BFormFile,
  BFormDatepicker,
  BCardText,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BContainer,
    BCard,
    BSpinner,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormSelect,
    BFormFile,
    BFormDatepicker,
    BCardText,
  },
  data(){
    return{
        statusOptions: ["New", "In Progress", "Won", "Lost"],
        isSelectDisabled: true, 
        isAmountReadOnly:true,
    }
  }
};
</script>
